import React, { ReactElement, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import { ButtonWithShadow, IconButton, Text } from '../../../UI';
import { Grid } from '../../../components/Grid';
import { ReactComponent as ArrowNextIcon } from '../../../../public/icons/arrow-next.svg';
import { ReactComponent as ArrowPrevIcon } from '../../../../public/icons/arrow-prev.svg';
import slide1 from '../../../../public/images/gallery-section/slide1.svg';
import slide2 from '../../../../public/images/gallery-section/slide2.svg';
import slide3 from '../../../../public/images/gallery-section/slide3.svg';
import slide4 from '../../../../public/images/gallery-section/slide4.svg';
import {
  ADVERTISING_LINK,
  COMPANIES_LINK,
  NTD_LINK,
  REGISTRATION_LINK,
} from '../../../constants';

const slides = [
  {
    title: 'Заявите о своем товаре',
    description: 'Вся промышленность в istock.info',
    img: slide2,
    href: REGISTRATION_LINK,
  },
  {
    title: 'Ваши заказчики в 3-х кликах',
    description: 'Получайте заказы с помощью современного каталога istock.info',
    img: slide4,
    href: COMPANIES_LINK,
  },
  {
    title: '>20 млн карточек стандартов',
    description: (
      <>
        Просто соберите каталог <br />
        Просто получите заказы
      </>
    ),
    img: slide3,
    href: NTD_LINK,
  },
  {
    title: 'Увеличьте число заказов',
    description: 'Вам помогут инструменты продвижения istock.info',
    img: slide1,
    href: ADVERTISING_LINK,
  },
];

const SlideWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;
    padding-top: 64px;

    ${mediaQueries.s} {
      height: inherit;
      padding-top: 34px;

      ${ButtonWithShadow} {
        display: none;
      }
    }
  `,
);

const TextWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    max-width: 680px;
    margin-bottom: 52px;

    ${mediaQueries.l} {
      max-width: 512px;
    }

    ${mediaQueries.m} {
      max-width: 460px;
      margin-bottom: 28px;
    }

    ${mediaQueries.s} {
      max-width: inherit;
    }
  `,
);

const ImageWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    width: 100%;
    margin-right: -41px;
    margin-top: -54px;

    ${mediaQueries.xl} {
      margin-right: 0;
    }

    ${mediaQueries.s} {
      margin-top: 0;
    }

    img {
      display: inline;
      width: 145%;
      max-width: inherit;

      ${mediaQueries.xl} {
        width: 100%;
      }
    }

    ${mediaQueries.s} {
      height: 260px;
      text-align: center;
      margin-bottom: 16px;

      img {
        max-height: 100%;
      }
    }
  `,
);

const SliderControls = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;

    ${mediaQueries.s} {
      margin-bottom: 68px;
    }
  `,
);

const SlideCounter = styled.div(
  ({ theme: { colors } }) => css`
    width: 60px;
    margin: 0 16px;
    text-align: center;
    color: ${colors.text.disabled};
    font-family: 'Barlow';
    font-weight: 300;
  `,
);

const SliderWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    flex-direction: row-reverse;

    ${mediaQueries.s} {
      flex-direction: column;
    }
  `,
);

const DescriptionSlide = styled.div`
  width: 100%;
`;

const GalleryContainer = styled.div(
  ({ theme: { mediaQueries } }) => css`
    ${mediaQueries.s} {
      margin-top: -40px;
    }
  `,
);

interface ISlideProps {
  title: string;
  description: ReactElement | string;
  img: string;
  href: string;
}

function Slide({ title, description, img, href }: ISlideProps): JSX.Element {
  return (
    <SlideWrapper>
      <Grid.FlexibleWrapper>
        <SliderWrapper>
          <ImageWrapper>
            <img src={img} alt='' />
          </ImageWrapper>
          <DescriptionSlide>
            <TextWrapper>
              <Text
                mb={['8px', '8px', 28]}
                fontWeight={600}
                fontSize={[6, 6, 7, 8, 9]}
              >
                {title}
              </Text>
              <Text
                fontSize={[4, 4, 5, 7, 8]}
                height={['inherit', 'inherit', 'inherit', 90]}
              >
                {description}
              </Text>
            </TextWrapper>
            <ButtonWithShadow
              as='a'
              size='m'
              target='_blank'
              rel='noreferrer'
              href={href}
            >
              Перейти в istock.info
            </ButtonWithShadow>
          </DescriptionSlide>
        </SliderWrapper>
      </Grid.FlexibleWrapper>
    </SlideWrapper>
  );
}

function GallerySection({ id }: ISection) {
  const sliderRef = useRef<any>();
  const [currentSlide, setCurrentSlide] = useState(1);

  const settings = useMemo(
    () => ({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      cssEase: 'ease-in-out',
      beforeChange: (_currentSlide: number, nextSlide: number) =>
        setCurrentSlide(nextSlide + 1),
      responsive: [
        {
          breakpoint: 800,
          settings: {
            autoplay: false,
            speed: 600,
            cssEase: 'linear',
          },
        },
      ],
    }),
    [],
  );

  return (
    <GalleryContainer id={id}>
      <Slider {...settings} ref={sliderRef}>
        {slides.map(item => (
          <Slide key={item.title} {...item} />
        ))}
      </Slider>
      <SliderControls>
        <IconButton rounded onClick={() => sliderRef.current.slickPrev()}>
          <ArrowPrevIcon />
        </IconButton>
        <SlideCounter>
          0{currentSlide} - 0{slides.length}
        </SlideCounter>
        <IconButton rounded onClick={() => sliderRef.current.slickNext()}>
          <ArrowNextIcon />
        </IconButton>
      </SliderControls>
    </GalleryContainer>
  );
}

export { GallerySection };
